var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-img",style:({
    top: _vm.top,
  }),on:{"click":_vm.selectFloor}},[_c('img',{style:({
      width: `${_vm.width}px`,
    }),attrs:{"src":_vm.img_src}}),(!_vm.disabled)?_c('div',{staticClass:"floor-card"},[_c('div',{class:['floor-card-line', _vm.isCurrent ? 'floor-card-line-select' : '']}),_c('div',{class:[
        'floor-card-floor',
        _vm.isCurrent ? 'floor-card-floor-select' : '',
      ]},[_vm._v(" "+_vm._s(_vm.room)+"层 ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
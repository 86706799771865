<template>
  <div class="build" :style="{ height: buildHeight, width: buildWidth }">
    <floor
      :class="[class_id]"
      v-for="(floor, index) in floorsArray"
      :key="floor"
      :floors="floorsArray.length"
      :width="width"
      :floor="index"
      :room="floor"
      :select="current_floor"
      :disabled="disabled"
      :hover="hover"
      @selectFloor="clickFloor(index)"
      @mouseenter.native="mouseenter"
      @mouseleave.native="mouseleave"
    >
    </floor>
    <div class="build-bottom" v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Floor from "./floor.vue";
export default {
  components: { Floor },
  data() {
    return {
      current_floor: null,
      hover: false,
      class_id: "",
      height: 0,
    };
  },
  props: {
    floors: {
      type: Number | Array,
      default: 20,
    },
    width: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    current_floor: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const floors = document.getElementsByClassName(this.class_id);
          if (floors.length > 0) {
            this.height = floors[0].offsetTop;
          }
        });
      },
    },
  },
  computed: {
    floorsArray() {
      //如果是数组，里面应该保存的是楼层数
      if (Array.isArray(this.floors)) {
        return JSON.parse(JSON.stringify(this.floors));
      }
      const list = [];
      for (let i = 1; i < this.floors; i++) {
        list.push(i);
        // if (list.length >= 20) {
        //   break;
        // }
      }
      return list;
    },
    buildHeight() {
      let floorHeight = this.width * (273 / 500) + this.height + 20;
      const footer = document.getElementsByClassName("build-bottom");
      if (footer.length > 0) {
        const footerHeight = footer[0].clientHeight;
        floorHeight += footerHeight;
      }
      return `${floorHeight}px`;
    },
    buildWidth() {
      let width = this.width || 200;
      if (!this.disabled) {
        width += 80;
      }
      return `${width}px`;
    },
  },
  created() {
    this._autoClass();
  },
  methods: {
    clickFloor(val) {
      if (this.disabled) return;
      this.current_floor = val;
      this.$emit("select", val);
    },
    mouseenter() {
      if (this.disabled) {
        this.hover = true;
      }
    },
    mouseleave() {
      this.hover = false;
    },
    _autoClass() {
      var d = new Date().getTime();
      var uuid = "xxxx-xxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
      this.class_id = uuid;
    },
  },
};
</script>

<style lang="less" scoped>
.build {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>

<template>
  <div
    class="floor-img"
    @click="selectFloor"
    :style="{
      top: top,
    }"
  >
    <img
      :src="img_src"
      :style="{
        width: `${width}px`,
      }"
    />
    <div class="floor-card" v-if="!disabled">
      <div
        :class="['floor-card-line', isCurrent ? 'floor-card-line-select' : '']"
      ></div>
      <div
        :class="[
          'floor-card-floor',
          isCurrent ? 'floor-card-floor-select' : '',
        ]"
      >
        {{ room }}层
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //楼层宽度
    width: Number,
    //当前楼层数,仅表示图中层数，真正的楼层数是room
    floor: Number,
    // 真实的楼层数
    room: String | Number,
    //选中的楼层数
    select: Number,
    //总层高
    floors: Number,
    disabled: Boolean,
    hover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isCurrent() {
      return this.select == this.floor;
    },
    img_src() {
      if (this.isCurrent || this.hover) return require("./img/floor_hl.png");
      return require("./img/floor_nl.png");
    },
    floorHeight() {
      const height = this.width * 0.546 * 0.24;
      return height;
    },
    offSet() {
      if (this.select != null) {
        if (this.select > this.floor) return 30;
        if (this.isCurrent) return 10;
      }
      return 0;
    },
    top() {
      const top =
        this.floors * this.floorHeight -
        this.floor * this.floorHeight +
        this.offSet;
      return `${top}px`;
    },
  },
  methods: {
    selectFloor() {
      this.$emit("selectFloor", this.floor);
    },
  },
};
</script>

<style lang="less" scoped>
.floor {
  &-img {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition-property: top;
    transition-duration: 300ms;
    & > img {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
    }
  }
  &-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: -8px;
    &-line {
      width: 23px;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      &-select {
        background: #19fbff;
      }
    }
    &-floor {
      width: 60px;
      height: 20px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      margin-top: 2px;
      text-align: center;
      user-select: none;
      &-select {
        background: linear-gradient(
          270deg,
          rgba(28, 47, 99, 0) 0%,
          rgba(0, 233, 255, 0.46) 100%
        );
        color: #19fbff;
        font-weight: 700;
        border: 2px solid #19fbff;
      }
    }
  }
}
</style>
